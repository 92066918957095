Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.btnExampleTitle = "CLICK ME";
exports.deleteApiEndPoint = "bx_block_catalogue/catalogues_variants/";
exports.tabsNames=["Buying Requests","Products","Orders","Offers","Wish Requests"]
exports.productDetailsPageColumns=[
  {label:"ID",id:"1"},
  {label:"Date",id:"2"},
  {label:"Customer",id:"3"},
  {label:"Location",id:"4"},
  {label:"Listed Price",id:"5"},
  {label:"Offer Price",id:"6"},
  {label:"Actions",id:"7"},
];

exports.subtabsMapping = {
  "Buying Requests":[],
  Products:["All Products","Hidden Products"],
  Orders: ['Pending Orders', 'Accepted Orders', 'Rejected Orders'],
  Offers: ['Pending Offers', 'Accepted Offers', 'Rejected Offers'],
  'Wish Requests': ['Pending Requests', 'Accepted Requests', 'Rejected Requests'],
};
exports.filterDateLabels={
  "Wish Requests":"Request Date",
  "Offers":"Offer Date",
  "Orders":"Order Date",
  "Buying Requests":"Listed Date",

}
exports.priceRangesLabels={
  "Offers":"Listed Range",
  "Orders":"Listed Price",
  "Buying Requests":"Listed Price",
  "Products":"Listed Range"
}
const COMMON_SORTING_OPTIONS = [
  { value: "recent", label: "Recent" },
];

const DATE_SORTING_OPTIONS = [
  { value: "request_date_new_to_old", label: "Request Date (New to Old)" },
  { value: "request_date_old_to_new", label: "Request Date (Old to New)" },
  { value: "assign_date_new_to_old", label: "Assign Date (New to Old)" },
  { value: "assign_date_old_to_new", label: "Assign Date (Old to New)" },
];

const PRICE_SORTING_OPTIONS = [
  { value: "price_high_to_low", label: "Price: High to Low" },
  { value: "price_low_to_high", label: "Price: Low to High" },
];

const REQUESTS_SORTING_OPTIONS = [
  { value: "requests_high_to_low", label: "Requests: High to Low" },
  { value: "requests_low_to_high", label: "Requests: Low to High" },
];

const SOLD_SORTING_OPTIONS = [
  { value: "sold_high_to_low", label: "Sold: High to Low" },
  { value: "sold_low_to_high", label: "Sold: Low to High" },
];

const OFFERS_SORTING_OPTIONS = [
  { value: "offers_high_to_low", label: "Offers: High to Low" },
  { value: "offers_low_to_high", label: "Offers: Low to High" },
];

const LISTED_PRICE_SORTING_OPTIONS = [
  { value: "listed_price_high_to_low", label: "Listed Price: High to Low" },
  { value: "listed_price_low_to_high", label: "Listed Price: Low to High" },
];

const OFFER_PRICE_SORTING_OPTIONS = [
  { value: "offer_price_high_to_low", label: "Offer Price: High to Low" },
  { value: "offer_price_low_to_high", label: "Offer Price: Low to High" },
];

exports.sortingOptions = {
  "Wish Requests": [...COMMON_SORTING_OPTIONS, ...DATE_SORTING_OPTIONS],
  "Buying Requests": [
    ...COMMON_SORTING_OPTIONS,
    ...PRICE_SORTING_OPTIONS,
    ...REQUESTS_SORTING_OPTIONS,
  ],
  "Products": [...COMMON_SORTING_OPTIONS, ...SOLD_SORTING_OPTIONS, ...OFFERS_SORTING_OPTIONS],
  "Orders": [...COMMON_SORTING_OPTIONS, ...PRICE_SORTING_OPTIONS],
  "Offers": [...COMMON_SORTING_OPTIONS, ...LISTED_PRICE_SORTING_OPTIONS, ...OFFER_PRICE_SORTING_OPTIONS],
};

exports.tabEndPoints={
  "Buying Requests":"/bx_block_catalogue/catalogues?show_buying_requests=true",
  Products:"/bx_block_catalogue/catalogues",
  Orders:"/bx_block_order_management/shopper_orders",
  Offers:"/bx_block_request_management/offer_requests/fetch_shopper_offer",
  "Wish Requests":"/bx_block_request_management/wish_requests/fetch_wish_lists"
}
// Customizable Area End